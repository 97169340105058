import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { getSupabase } from './utils/supabaseClient';
import { isProfileComplete } from './utils/profileUtils';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const supabase = getSupabase();

  useEffect(() => {
    const handleAuthCallback = async () => {
      console.log("AuthCallback - handling callback");
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error during auth callback:', error);
        navigate('/login');
        return;
      }

      if (data?.session?.user) {
        console.log('User authenticated:', data.session.user);
        setUser(data.session.user);

        try {
          const profileComplete = await isProfileComplete(data.session.user.id);
          console.log('Profile complete:', profileComplete);
          if (profileComplete) {
            navigate('/dashboard');
          } else {
            navigate('/profile');
          }
        } catch (error) {
          console.error('Error checking profile completeness:', error);
          navigate('/profile');
        }
      } else {
        console.log('No user found, redirecting to login');
        navigate('/login');
      }
    };

    handleAuthCallback();
  }, [navigate, setUser, supabase.auth]);

  return null;
};

export default AuthCallback;