import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-8rem)]">
      <div className="flex flex-col md:flex-row items-center justify-between mb-12 max-w-6xl w-full px-4">
        <div className="text-center md:text-left md:w-1/2 mb-8 md:mb-0">
          <img src="/images/flomingl-logo.svg" alt="Flomingl Logo" className="h-24 w-auto mb-4 mx-auto md:mx-0" />
          <h2 className="text-2xl text-[#ed1942] mb-4">Flock to New Connections!</h2>
          <p className="text-gray-800 mb-8">
            Flomingl is an innovative event networking app that revolutionizes how professionals connect at conferences, trade shows, and networking events. Our platform streamlines the networking process, making it easier than ever to forge meaningful connections and expand your professional network.
          </p>
          <button onClick={handleGetStarted} className="bg-[#161247] hover:bg-opacity-90 text-white font-bold py-3 px-6 rounded-full text-lg mx-auto md:mx-0">
            Get Started
          </button>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img src="/images/flamingos-flomingl.png" alt="Flamingos" className="max-w-full h-auto" />
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-8 max-w-6xl w-full px-4">
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center justify-center text-center">
          <i className="fas fa-qrcode text-4xl text-[#ed1942] mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">QR Code Networking</h3>
          <p className="text-gray-600">Instantly connect with other professionals using our QR code system.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center justify-center text-center">
          <i className="fas fa-robot text-4xl text-[#ed1942] mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">AI-Powered Icebreakers</h3>
          <p className="text-gray-600">Break the ice with AI-generated conversation starters tailored to your connections.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center justify-center text-center">
          <i className="fas fa-users text-4xl text-[#ed1942] mb-4"></i>
          <h3 className="text-xl font-semibold mb-2">Event Management</h3>
          <p className="text-gray-600">Organize and manage networking events with ease using our powerful tools.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;