import { getSupabase } from './supabaseClient';

export const isProfileComplete = async (userId) => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from('profiles')
    .select('first_name, last_name, phone, bio')
    .eq('id', userId)
    .single();

  if (error) {
    console.error('Error checking profile completeness:', error);
    return false;
  }

  return !!(data && data.first_name && data.last_name && data.phone && data.bio);
};