import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSupabase } from '../utils/supabaseClient';
import FlamingoIdenticon from '../components/FlamingoIdenticon';
import { useAuth } from '../contexts/AuthContext';

const UserProfile = () => {
  const { userIdentifier } = useParams();
  const [profile, setProfile] = useState(null);
  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const supabase = getSupabase();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchProfileAndConnection = async () => {
      if (!userIdentifier) {
        setError('No user identifier provided');
        setLoading(false);
        return;
      }

      try {
        let query = supabase.from('profiles').select('*');
        
        if (userIdentifier.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
          query = query.eq('id', userIdentifier);
        } else {
          query = query.eq('username', userIdentifier);
        }

        const { data: profileData, error: profileError } = await query.single();

        if (profileError) throw profileError;
        setProfile(profileData);

        if (user && user.id !== profileData.id) {
          const { data: connectionData, error: connectionError } = await supabase
            .from('connections')
            .select('*')
            .eq('scanner_id', user.id)
            .eq('scanned_id', profileData.id)
            .single();

          if (connectionError && connectionError.code !== 'PGRST116') {
            throw connectionError;
          }

          setConnection(connectionData);
        }
      } catch (error) {
        console.error('Error fetching profile or connection:', error);
        setError('Failed to load user profile or connection');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndConnection();
  }, [userIdentifier, supabase, user]);

  const handleAddToFlock = async () => {
    try {
      const { data, error } = await supabase
        .from('connections')
        .upsert({
          scanner_id: user.id,
          scanned_id: profile.id,
          added_to_flock: true,
          added_to_flock_at: new Date().toISOString(),
          last_scanned_at: new Date().toISOString(),
          scan_count: connection ? connection.scan_count + 1 : 1
        })
        .select()
        .single();

      if (error) throw error;
      setConnection(data);
    } catch (error) {
      console.error('Error adding to flock:', error);
      setError('Failed to add user to your flock');
    }
  };

  const handleGenerateIcebreaker = () => {
    // Placeholder for icebreaker generation
    console.log('Generate Icebreaker clicked');
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!profile) {
    return <div className="text-center">User not found</div>;
  }

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      <div className="w-24 h-24 rounded-full overflow-hidden shadow-lg mb-4">
        {profile.profile_image ? (
          <img src={profile.profile_image} alt="Profile" className="w-full h-full object-cover" />
        ) : (
          <FlamingoIdenticon userId={profile.id} size={96} />
        )}
      </div>
      <h1 className="text-2xl font-bold mb-2">
        {profile.first_name} {profile.last_name?.charAt(0)}.
      </h1>
      <p className="text-gray-600 mb-4">@{profile.username}</p>
      
      {user && user.id !== profile.id && (
        <>
          <button
            onClick={handleAddToFlock}
            className="bg-[#161247] text-white rounded-full py-3 px-6 hover:bg-opacity-90 transition-opacity duration-300 mb-4"
          >
            🦩 {connection && connection.added_to_flock ? 'Added to Flock' : 'Add to my Flock'}
          </button>
          
          <button 
            onClick={handleGenerateIcebreaker}
            className="bg-white border-2 border-[#ed1942] text-[#ed1942] rounded-full py-3 px-6 hover:bg-[#ed1942] hover:text-white transition-colors duration-300 mb-4"
          >
            💬 Generate Icebreaker
          </button>
        </>
      )}

      <button
        onClick={() => navigate('/dashboard')}
        className="bg-gray-200 text-gray-700 rounded-full py-3 px-6 hover:bg-gray-300 transition-colors duration-300"
      >
        Back to My Profile
      </button>
    </div>
  );
};

export default UserProfile;