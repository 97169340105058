import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { isProfileComplete } from '../utils/profileUtils';
import { getSupabase } from '../utils/supabaseClient';

const Login = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user, signIn } = useAuth();
  const navigate = useNavigate();
  const supabase = getSupabase();

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      if (user) {
        try {
          const profileComplete = await isProfileComplete(user.id);
          if (profileComplete) {
            navigate('/dashboard');
          } else {
            navigate('/profile');
          }
        } catch (error) {
          console.error('Error checking profile completeness:', error);
          navigate('/profile');
        }
      } else {
        setIsLoading(false);
      }
    };

    checkAuthAndRedirect();
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    try {
      await signIn(email);
      setMessage('Magic link sent successfully.\nPlease check your email.');
    } catch (error) {
      console.error('Error signing in:', error);
      setMessage(error.message || 'An error occurred while sending the magic link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-[calc(100vh-8rem)]">Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-8rem)]">
      <div className="w-full max-w-md px-4">
        <div className="text-center mb-8">
          <img src="/images/flomingl-logo.svg" alt="Flomingl Logo" className="h-24 w-auto mx-auto mb-4" />
          <h2 className="text-2xl text-[#ed1942] mb-4">Sign in to Flomingl</h2>
          <p className="text-gray-800 mb-8">
            Enter your email to receive a magic link for instant access.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#161247]"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="w-full bg-[#161247] text-white font-bold py-2 px-4 rounded-full hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#161247] disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? 'Sending Magic Link...' : 'Register / Sign In'}
          </button>
        </form>

        {message && (
          <div className="mt-1 text-center">
            <div className="p-4 text-green-700">
              <p className="font-bold">{message}</p>
            </div>
            <div className="text-4xl mb-2">📧</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;