import React from 'react'
import PropTypes from 'prop-types'

export default function FlamingoIdenticon({ userId = 'default', size = 100 }) {
  // Ensure userId is a non-empty string
  const id = userId && userId.trim() !== '' ? userId : 'default'

  // Generate a simple hash from the userId (UUID)
  const hash = id.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)

  // Use the hash to generate flamingo features
  const pinkHue = 340 + (Math.abs(hash) % 30) // 340-370 (wraps around to 0-10)
  const neckCurve = Math.abs(hash % 5) - 2 // -2, -1, 0, 1, or 2
  const eyePositionX = 45 + (Math.abs(hash) % 10) // 45-54
  const eyePositionY = 15 + (Math.abs(hash >> 4) % 10) // 15-24
  const beakShape = Math.abs(hash) % 3 // 0, 1, or 2
  const legStyle = Math.abs(hash >> 12) % 3 // 0, 1, or 2
  const grassCount = 3 + (Math.abs(hash >> 16) % 5) // 3 to 7 grass blades

  // Colors
  const skyColor = `hsl(${200 + (hash % 40)}, 70%, 80%)`
  const waterColor = `hsl(${180 + (hash % 40)}, 70%, 60%)`
  const bodyColor = `hsl(${pinkHue}, 100%, 70%)`
  const neckColor = `hsl(${pinkHue}, 100%, 60%)`
  const headColor = `hsl(${pinkHue}, 100%, 75%)`

  // Generate grass
  const grass = Array.from({ length: grassCount }, (_, i) => {
    const x = 5 + (Math.abs(hash >> (i * 3)) % 90)
    const height = 5 + (Math.abs(hash >> (i * 3 + 1)) % 10)
    const curve = (Math.abs(hash >> (i * 3 + 2)) % 10) - 5
    const grassHue = 80 + (Math.abs(hash >> (i * 5)) % 40)
    return (
      <path
        key={`grass-${i}`}
        d={`M${x} 100 Q${x + curve} ${100 - height} ${x} ${95 - height}`}
        stroke={`hsl(${grassHue}, 70%, 40%)`}
        strokeWidth="1.5"
        fill="none"
      />
    )
  })

  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      {/* Sky */}
      <rect width="100" height="60" fill={skyColor} />
      
      {/* Water */}
      <path d="M0 60 Q50 70 100 60 L100 100 L0 100 Z" fill={waterColor} />
      
      {/* Grass */}
      {grass}
      
      {/* Body */}
      <ellipse cx="50" cy="65" rx="25" ry="20" fill={bodyColor} />
      
      {/* Neck */}
      <path
        d={`M50 65 Q ${50 + neckCurve * 5} 40, 50 20`}
        stroke={neckColor}
        strokeWidth="10"
        fill="none"
      />
      
      {/* Head */}
      <circle cx="50" cy="20" r="12" fill={headColor} />
      
      {/* Eyes */}
      <circle cx={eyePositionX} cy={eyePositionY} r="3" fill="black" />
      <circle cx={100 - eyePositionX} cy={eyePositionY} r="3" fill="black" />
      
      {/* Beak */}
      {beakShape === 0 && (
        <path d="M50 20 L65 25 L50 30" fill="orange" />
      )}
      {beakShape === 1 && (
        <path d="M50 20 Q60 25, 50 30" stroke="orange" strokeWidth="4" fill="none" />
      )}
      {beakShape === 2 && (
        <path d="M50 20 L60 25 L50 30 L55 25 Z" fill="orange" />
      )}
      
      {/* Legs */}
      {legStyle === 0 && (
        <>
          <line x1="40" y1="85" x2="40" y2="100" stroke="black" strokeWidth="3" />
          <line x1="60" y1="85" x2="60" y2="100" stroke="black" strokeWidth="3" />
        </>
      )}
      {legStyle === 1 && (
        <>
          <path d="M40 85 Q35 92, 40 100" stroke="black" strokeWidth="3" fill="none" />
          <path d="M60 85 Q65 92, 60 100" stroke="black" strokeWidth="3" fill="none" />
        </>
      )}
      {legStyle === 2 && (
        <>
          <path d="M40 85 L35 100 M40 85 L45 100" stroke="black" strokeWidth="3" />
          <path d="M60 85 L55 100 M60 85 L65 100" stroke="black" strokeWidth="3" />
        </>
      )}
    </svg>
  )
}

FlamingoIdenticon.propTypes = {
  userId: PropTypes.string,
  size: PropTypes.number
}