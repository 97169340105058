import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'qr-scanner';

const Scanner = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState(null);

  useEffect(() => {
    let scanner;
    const initializeScanner = async () => {
      if (videoRef.current && !qrScanner) {
        try {
          scanner = new QrScanner(
            videoRef.current,
            result => {
              if (result) {
                // Extract just the last part of the path, which should be the username or UUID
                const userIdentifier = result.data.split('/').pop();
                navigate(`/${userIdentifier}`);
              }
            },
            {
              onDecodeError: (error) => {
                // Only log actual errors, not "No QR code found"
                if (error !== 'No QR code found') {
                  console.error(error);
                  setError('Error scanning QR code. Please try again.');
                }
              },
              highlightScanRegion: true,
              highlightCodeOutline: true,
              returnDetailedScanResult: true,
            }
          );

          await scanner.start();
          setQrScanner(scanner);
        } catch (err) {
          console.error('Failed to start scanner:', err);
          setError('Failed to start camera. Please ensure you have given camera permissions and are using HTTPS.');
        }
      }
    };

    initializeScanner();

    return () => {
      if (scanner) {
        scanner.destroy();
      }
    };
  }, [navigate]);

  const handleCancel = () => {
    if (qrScanner) {
      qrScanner.destroy();
    }
    navigate('/dashboard');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-white">
      <div className="text-center">
        <h1 className="text-2xl font-bold">Scan QR Code</h1>
        <p className="text-sm text-gray-600">Hold your device up to the QR code to scan</p>
      </div>
      <div className="my-8 w-full max-w-md">
        <video ref={videoRef} className="w-full" />
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <button
        onClick={handleCancel}
        className="bg-red-500 text-white rounded-full py-3 px-6 hover:opacity-90 transition-opacity duration-300"
      >
        Cancel
      </button>
    </div>
  );
};

export default Scanner;