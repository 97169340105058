import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSupabase } from '../utils/supabaseClient';
import FlamingoIdenticon from '../components/FlamingoIdenticon';
import { useAuth } from '../contexts/AuthContext';
import { FaEnvelope, FaPhone, FaSms, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import { format, formatDistanceToNow } from 'date-fns';

const ConnectionProfile = () => {
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const supabase = getSupabase();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchProfileAndConnection = async () => {
      if (!user) return;

      try {
        // Fetch profile
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('username', username)
          .single();

        if (profileError) throw profileError;
        setProfile(profileData);

        // Fetch connection
        const { data: connectionData, error: connectionError } = await supabase
          .from('connections')
          .select('*')
          .or(`and(scanner_id.eq.${user.id},scanned_id.eq.${profileData.id}),and(scanner_id.eq.${profileData.id},scanned_id.eq.${user.id})`)
          .single();

        if (connectionError && connectionError.code !== 'PGRST116') {
          throw connectionError;
        }

        setConnection(connectionData);
      } catch (error) {
        console.error('Error fetching profile or connection:', error);
        setError('Failed to load connection profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndConnection();
  }, [username, user, supabase]);

  const isInTheirFlock = connection && 
    ((connection.scanner_id === user.id && connection.scanned_added_to_flock) ||
     (connection.scanned_id === user.id && connection.scanner_added_to_flock));

  const isInMyFlock = connection && 
    ((connection.scanner_id === user.id && connection.scanner_added_to_flock) ||
     (connection.scanned_id === user.id && connection.scanned_added_to_flock));

  const toggleFlock = async () => {
    try {
      const columnToUpdate = connection.scanner_id === user.id ? 'scanner_added_to_flock' : 'scanned_added_to_flock';
      const { data, error } = await supabase
        .from('connections')
        .update({ 
          [columnToUpdate]: !isInMyFlock,
          [`${columnToUpdate}_at`]: !isInMyFlock ? new Date().toISOString() : null
        })
        .eq('id', connection.id)
        .select();

      if (error) throw error;
      setConnection(data[0]);
    } catch (error) {
      console.error('Error toggling flock status:', error);
      setError('Failed to update flock status');
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!profile || !connection) {
    return <div className="text-center">Connection not found</div>;
  }

  const ContactButton = ({ icon, label, href, show }) => {
    if (!show) return null;
    return (
      <a
        href={href}
        className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-full mr-2 mb-2 transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
        <span className="ml-2">{label}</span>
      </a>
    );
  };

  const getSocialMediaUrl = (platform, username) => {
    switch (platform) {
      case 'linkedin':
        return `https://www.linkedin.com/in/${username}`;
      case 'twitter':
        return `https://twitter.com/${username}`;
      case 'instagram':
        return `https://www.instagram.com/${username}`;
      default:
        return '';
    }
  };

  const formatConnectionDate = (date) => {
    return format(new Date(date), "EEEE, MMMM do yyyy 'at' h:mm a");
  };

  const getLastConnectedText = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col items-center mb-6">
          <div className="w-32 h-32 rounded-full overflow-hidden mb-4">
            {profile.profile_image ? (
              <img src={profile.profile_image} alt="Profile" className="w-full h-full object-cover" />
            ) : (
              <FlamingoIdenticon userId={profile.id} size={128} />
            )}
          </div>
          <h1 className="text-3xl font-bold text-center text-[#161247]">{profile.first_name} {profile.last_name}</h1>
          <p className="text-gray-600 mb-2">@{profile.username}</p>
          <button
            onClick={toggleFlock}
            className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-semibold transition-colors duration-300 ${
              isInMyFlock
                ? 'bg-green-100 text-green-800 hover:bg-green-200'
                : 'bg-[#ed1942] text-white hover:bg-opacity-90'
            }`}
          >
            {isInMyFlock ? '🦩 In Flock' : '🦩 Add to Flock'}
          </button>
        </div>

        {isInTheirFlock && (
          <div className="mb-6 text-center">
            <h2 className="text-xl font-semibold mb-4 text-[#161247]">Contact Information</h2>
            <div className="flex flex-wrap justify-center">
              <ContactButton icon={<FaEnvelope />} label="Email" href={`mailto:${profile.email}`} show={profile.email} />
              <ContactButton icon={<FaPhone />} label="Call" href={`tel:${profile.phone}`} show={profile.phone} />
              <ContactButton icon={<FaSms />} label="SMS" href={`sms:${profile.phone}`} show={profile.phone} />
              <ContactButton icon={<FaLinkedin />} label="LinkedIn" href={getSocialMediaUrl('linkedin', profile.linkedin)} show={profile.linkedin} />
              <ContactButton icon={<FaTwitter />} label="Twitter" href={getSocialMediaUrl('twitter', profile.twitter)} show={profile.twitter} />
              <ContactButton icon={<FaInstagram />} label="Instagram" href={getSocialMediaUrl('instagram', profile.instagram)} show={profile.instagram} />
            </div>
          </div>
        )}

        {profile.bio && (
          <div className="mb-6 text-center">
            <h2 className="text-xl font-semibold mb-2 text-[#161247]">Flomingl Facts</h2>
            <p className="text-gray-700 bg-gray-50 p-4 rounded-lg">{profile.bio}</p>
          </div>
        )}

        <div className="mb-6 text-center">
          <h2 className="text-xl font-semibold mb-4 text-[#161247]">Connection Details</h2>
          <div className="bg-gray-50 p-4 rounded-lg inline-block">
            <p className="text-gray-700 mb-2">
              You first connected with {profile.first_name} on {formatConnectionDate(connection.first_scanned_at)}
            </p>
            <p className="text-gray-700 mb-2">
              <span title={formatConnectionDate(connection.last_scanned_at)} className="cursor-help border-b border-dotted border-gray-500">
                You last connected {getLastConnectedText(connection.last_scanned_at)}
              </span>
            </p>
            <p className="text-gray-700">
              You have connected {connection.scan_count} time{connection.scan_count !== 1 ? 's' : ''}
            </p>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={() => navigate('/connections')}
            className="bg-gray-200 text-gray-700 rounded-full py-3 px-6 hover:bg-gray-300 transition-colors duration-300"
          >
            Back to Connections
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionProfile;