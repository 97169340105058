import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getSupabase } from '../utils/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import FlamingoIdenticon from '../components/FlamingoIdenticon';
import { FaEnvelope, FaPhone, FaSms, FaLinkedin, FaTwitter, FaInstagram, FaSortAlphaDown, FaSortNumericDownAlt } from 'react-icons/fa';

const Connections = () => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('lastConnected');
  const supabase = getSupabase();
  const { user } = useAuth();

  useEffect(() => {
    fetchConnections();
  }, [user]);

  const fetchConnections = async () => {
    if (!user) return;

    try {
      const { data: connectionsData, error: connectionsError } = await supabase
        .from('connections')
        .select('*')
        .or(`scanner_id.eq.${user.id},scanned_id.eq.${user.id}`);

      if (connectionsError) throw connectionsError;

      const userIds = new Set(connectionsData.flatMap(conn => [conn.scanner_id, conn.scanned_id]));
      userIds.delete(user.id);

      const { data: profilesData, error: profilesError } = await supabase
        .from('profiles')
        .select('*')
        .in('id', Array.from(userIds));

      if (profilesError) throw profilesError;

      const combinedConnections = connectionsData.map(conn => ({
        ...conn,
        isScanner: conn.scanner_id === user.id,
        otherUser: profilesData.find(profile => profile.id === (conn.scanner_id === user.id ? conn.scanned_id : conn.scanner_id))
      }));

      setConnections(combinedConnections);
    } catch (error) {
      console.error('Error fetching connections:', error);
      setError('Failed to load connections');
    } finally {
      setLoading(false);
    }
  };

  const toggleFlock = async (connectionId, currentStatus, isScanner) => {
    try {
      const columnToUpdate = isScanner ? 'scanner_added_to_flock' : 'scanned_added_to_flock';
      const timeColumnToUpdate = isScanner ? 'scanner_added_to_flock_at' : 'scanned_added_to_flock_at';
      const { data, error } = await supabase
        .from('connections')
        .update({ 
          [columnToUpdate]: !currentStatus,
          [timeColumnToUpdate]: !currentStatus ? new Date().toISOString() : null
        })
        .eq('id', connectionId)
        .select();

      if (error) throw error;

      if (data && data.length > 0) {
        setConnections(prevConnections =>
          prevConnections.map(conn =>
            conn.id === connectionId 
              ? { ...conn, [columnToUpdate]: !currentStatus, [timeColumnToUpdate]: !currentStatus ? new Date().toISOString() : null }
              : conn
          )
        );
      }
    } catch (error) {
      console.error('Error toggling flock status:', error);
      setError('Failed to update flock status');
    }
  };

  const sortConnections = (connections) => {
    switch (sortBy) {
      case 'lastConnected':
        return connections.sort((a, b) => new Date(b.last_scanned_at) - new Date(a.last_scanned_at));
      case 'alphabetical':
        return connections.sort((a, b) => a.otherUser.last_name.localeCompare(b.otherUser.last_name));
      default:
        return connections;
    }
  };

  if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  const sortedConnections = sortConnections([...connections]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">My Connections</h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setSortBy('lastConnected')}
            className={`p-2 rounded ${sortBy === 'lastConnected' ? 'bg-[#ed1942] text-white' : 'bg-gray-200'}`}
            title="Sort by last connected"
          >
            <FaSortNumericDownAlt />
          </button>
          <button
            onClick={() => setSortBy('alphabetical')}
            className={`p-2 rounded ${sortBy === 'alphabetical' ? 'bg-[#ed1942] text-white' : 'bg-gray-200'}`}
            title="Sort alphabetically"
          >
            <FaSortAlphaDown />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {sortedConnections.map((connection) => (
          <ConnectionCard
            key={connection.id}
            connection={connection}
            toggleFlock={toggleFlock}
            currentUserId={user.id}
          />
        ))}
      </div>
    </div>
  );
};

const ConnectionCard = ({ connection, toggleFlock, currentUserId }) => {
  const isScanner = connection.scanner_id === currentUserId;
  const isInMyFlock = isScanner ? connection.scanner_added_to_flock : connection.scanned_added_to_flock;
  const hasAddedMeToFlock = isScanner ? connection.scanned_added_to_flock : connection.scanner_added_to_flock;

  const handleToggleFlock = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleFlock(connection.id, isInMyFlock, isScanner);
  };

  const ContactIcon = ({ icon, href, show }) => {
    if (!show) return null;
    return (
      <a href={href} className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full text-gray-600 hover:bg-gray-200 transition-colors duration-200" target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    );
  };

  const cardContent = (
    <div className="flex items-center space-x-4">
      <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
        {connection.otherUser.profile_image ? (
          <img
            src={connection.otherUser.profile_image}
            alt={`${connection.otherUser.first_name}'s profile`}
            className="w-full h-full object-cover"
          />
        ) : (
          <FlamingoIdenticon userId={connection.otherUser.id} size={64} />
        )}
      </div>
      <div className="flex-grow min-w-0">
        <h2 className={`text-lg font-semibold truncate ${hasAddedMeToFlock ? 'text-[#ed1942]' : ''}`}>
          {hasAddedMeToFlock && <span title="Added you to their flock" className="mr-1">🦩</span>}
          {connection.otherUser.first_name} {connection.otherUser.last_name}
        </h2>
        <p className="text-sm text-gray-600 truncate">@{connection.otherUser.username}</p>
        <p className="text-xs text-gray-500 mt-1">
          Last connected: {new Date(connection.last_scanned_at).toLocaleDateString()}
        </p>
        <button
          onClick={handleToggleFlock}
          className={`mt-2 inline-block px-3 py-1 rounded-full text-xs ${
            isInMyFlock
              ? 'bg-green-100 text-green-800'
              : 'bg-gray-100 text-gray-800'
          }`}
        >
          {isInMyFlock ? '🦩 In Flock' : '🦩 Add to Flock'}
        </button>
      </div>
      <div className="flex-shrink-0 grid grid-cols-2 gap-2">
        <ContactIcon icon={<FaPhone size={16} />} href={`tel:${connection.otherUser.phone}`} show={connection.otherUser.phone} />
        <ContactIcon icon={<FaSms size={16} />} href={`sms:${connection.otherUser.phone}`} show={connection.otherUser.phone} />
        <ContactIcon icon={<FaLinkedin size={16} />} href={`https://www.linkedin.com/in/${connection.otherUser.linkedin}`} show={connection.otherUser.linkedin} />
        <ContactIcon icon={<FaTwitter size={16} />} href={`https://twitter.com/${connection.otherUser.twitter}`} show={connection.otherUser.twitter} />
        <ContactIcon icon={<FaInstagram size={16} />} href={`https://www.instagram.com/${connection.otherUser.instagram}`} show={connection.otherUser.instagram} />
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-4 transition-shadow duration-200 hover:shadow-lg">
      {hasAddedMeToFlock ? (
        <Link to={`/connections/${connection.otherUser.username}`} className="block">
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </div>
  );
};

export default Connections;