import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSupabase } from '../utils/supabaseClient';
import { parsePhoneNumber, formatIncompletePhoneNumber, AsYouType } from 'libphonenumber-js';
import FlamingoIdenticon from './FlamingoIdenticon';
import ProfileImageUpload from './ProfileImageUpload';
import { isProfileComplete } from '../utils/profileUtils';
import { useAuth } from '../contexts/AuthContext';
import { debounce } from 'lodash';

const UserProfileForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    phone: '',
    bio: '',
    linkedin: '',
    x: '',
    instagram: '',
    interests: [],
    profileImage: null,
  });
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const supabase = getSupabase();
  const { user } = useAuth();

  const [newEmail, setNewEmail] = useState('');
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [emailChangeMessage, setEmailChangeMessage] = useState('');

  const [phoneInputValue, setPhoneInputValue] = useState('');

  const [completeness, setCompleteness] = useState(0);

  const [error, setError] = useState('');

  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [usernameError, setUsernameError] = useState('');

  const [usernameSuggestions, setUsernameSuggestions] = useState([]);

  const checkUsernameAvailability = async (username) => {
    if (username.length < 3) {
      setUsernameError('Username must be at least 3 characters long');
      setUsernameAvailable(false);
      return;
    }

    try {
      // Check against database reserved list
      const { data: reservedData, error: reservedError } = await supabase
        .from('reserved_usernames')
        .select('username')
        .eq('username', username.toLowerCase())
        .single();

      if (reservedData) {
        setUsernameError('This username is reserved');
        setUsernameAvailable(false);
        return;
      }

      // Check if username is taken by another user
      const { data, error } = await supabase
        .from('profiles')
        .select('id, username')
        .eq('username', username.toLowerCase())
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      const isAvailable = !data || data.id === user.id;
      setUsernameAvailable(isAvailable);
      setUsernameError(isAvailable ? '' : 'Username is already taken');
    } catch (error) {
      console.error('Error checking username:', error);
      setUsernameError('Error checking username availability');
      setUsernameAvailable(false);
    }
  };

  const debouncedCheckUsername = debounce(checkUsernameAvailability, 300);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          setEmail(user.email || '');
          const { data, error } = await supabase
            .from('profiles')
            .select('first_name, last_name, username, phone, bio, linkedin, x, instagram, interests, profile_image')
            .eq('id', user.id)
            .single();

          if (error) {
            console.error('Error fetching user profile:', error);
          } else if (data) {
            setFormData({
              firstName: data.first_name || '',
              lastName: data.last_name || '',
              username: data.username || '',
              phone: data.phone || '',
              bio: data.bio || '',
              linkedin: data.linkedin || '',
              x: data.x || '',
              instagram: data.instagram || '',
              interests: data.interests || [],
              profileImage: data.profile_image || null,
            });
            setPhoneInputValue(formatIncompletePhoneNumber(data.phone || '', 'US'));
          }
        }
      } catch (error) {
        console.error('Error in fetchUserData:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    calculateCompleteness();
  }, [formData]);

  const calculateCompleteness = () => {
    const fields = [
      'firstName', 'lastName', 'username', 'phone', 'bio', 
      'linkedin', 'x', 'instagram', 'profileImage'
    ];
    
    let filledFields = fields.filter(field => formData[field] && formData[field].length > 0);
    
    if (formData.interests && formData.interests.length > 0) {
      filledFields.push('interests');
    }

    const totalFields = fields.length + 1;
    const percentage = (filledFields.length / totalFields) * 100;
    setCompleteness(Math.round(percentage));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      debouncedCheckUsername(value);
    }
    if (name === 'phone') {
      const formattedValue = new AsYouType('US').input(value);
      setPhoneInputValue(formattedValue);
      const phoneNumber = parsePhoneNumber(formattedValue, 'US');
      if (phoneNumber) {
        setFormData(prev => ({ ...prev, [name]: phoneNumber.format('E.164') }));
      }
    } else if (['linkedin', 'x', 'instagram'].includes(name)) {
      setFormData(prev => ({ ...prev, [name]: extractUsername(name, value) }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const extractUsername = (platform, value) => {
    switch (platform) {
      case 'linkedin':
        return value.replace(/^https?:\/\/(www\.)?linkedin\.com\/in\//, '').replace(/\/$/, '');
      case 'x':
        return value.replace(/^@/, '').replace(/^https?:\/\/(www\.)?twitter\.com\//, '').replace(/\/$/, '');
      case 'instagram':
        return value.replace(/^@/, '').replace(/^https?:\/\/(www\.)?instagram\.com\//, '').replace(/\/$/, '');
      default:
        return value;
    }
  };

  const handleInterestChange = (interest) => {
    setFormData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const validatePhone = (phone) => {
    try {
      const phoneNumber = parsePhoneNumber(phone, 'US');
      return phoneNumber.isValid();
    } catch (error) {
      return false;
    }
  };

  const nextStep = (e) => {
    e.preventDefault();
    if (step === 1 && !usernameAvailable) {
      setError('Please choose an available username');
      return;
    }
    setStep(s => Math.min(s + 1, 3));
  };

  const prevStep = () => {
    setStep(prevStep => {
      const newStep = prevStep - 1;
      return Math.max(newStep, 1);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step !== 3) {
      setError('Form submitted before reaching final step');
      return;
    }
    if (!usernameAvailable) {
      setError('Please choose an available username');
      return;
    }
    setLoading(true);
    setError('');

    if (!validatePhone(formData.phone)) {
      setError('Please enter a valid phone number');
      setLoading(false);
      return;
    }

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      setError('No authenticated user found');
      setLoading(false);
      return;
    }

    const { error: upsertError } = await supabase
      .from('profiles')
      .upsert({
        id: user.id,
        first_name: formData.firstName,
        last_name: formData.lastName,
        username: formData.username,
        phone: formData.phone,
        bio: formData.bio,
        linkedin: formData.linkedin,
        x: formData.x,
        instagram: formData.instagram,
        interests: formData.interests,
        profile_image: formData.profileImage,
      });

    if (upsertError) {
      setError('Error updating profile: ' + upsertError.message);
      console.error('Error:', upsertError);
    } else {
      const profileComplete = await isProfileComplete(user.id);
      if (profileComplete) {
        navigate('/dashboard');
      } else {
        setError('Please fill out all required fields: First Name, Last Name, Phone, and Bio');
      }
    }

    setLoading(false);
  };

  const handleEmailChangeRequest = async (e) => {
    e.preventDefault();
    if (!validateEmailChange()) return;

    setIsChangingEmail(true);
    setEmailChangeMessage('');

    try {
      const { error } = await supabase.auth.updateUser({ email: newEmail });

      if (error) throw error;

      setEmailChangeMessage('Verification email sent. Please check your inbox to confirm the change.');
      setNewEmail('');
    } catch (error) {
      setEmailChangeMessage(`Error: ${error.message}`);
    } finally {
      setIsChangingEmail(false);
    }
  };

  const validateEmailChange = () => {
    if (!newEmail) {
      setEmailChangeMessage('New email is required.');
      return false;
    }
    if (newEmail === email) {
      setEmailChangeMessage('New email must be different from current email.');
      return false;
    }
    return true;
  };

  const handleProfileImageUpdate = async (publicUrl) => {
    setFormData(prev => ({ ...prev, profileImage: publicUrl }));
  };

  const handleProfileImageDelete = async () => {
    if (!formData.profileImage) return;

    setLoading(true);
    setError('');
    const { data: { user } } = await supabase.auth.getUser();
    
    const filePath = formData.profileImage.split('/').slice(-2).join('/');

    const { error: deleteStorageError } = await supabase.storage
      .from('avatars')
      .remove([filePath]);

    if (deleteStorageError) {
      console.error('Error deleting image from storage:', deleteStorageError);
      setError('Error deleting image from storage');
      setLoading(false);
      return;
    }

    const { error: updateError } = await supabase
      .from('profiles')
      .update({ profile_image: null })
      .eq('id', user.id);

    if (updateError) {
      console.error('Error updating profile:', updateError);
      setError('Error updating profile');
    } else {
      setFormData(prev => ({ ...prev, profileImage: null }));
    }

    setLoading(false);
  };

  const getNextButtonText = () => {
    switch(step) {
      case 1:
        return "Next: About You";
      case 2:
        return "Next: Social Links";
      default:
        return "Next";
    }
  };

  const generateUsernameSuggestions = async () => {
    const base = formData.firstName.toLowerCase() + formData.lastName.toLowerCase();
    const suggestions = [
      base,
      `${base}${Math.floor(Math.random() * 100)}`,
      `${base}${Math.floor(Math.random() * 1000)}`,
      `${formData.firstName.toLowerCase()}_${formData.lastName.toLowerCase()}`,
      `${formData.firstName.toLowerCase()[0]}${formData.lastName.toLowerCase()}${Math.floor(Math.random() * 100)}`
    ];

    const availableSuggestions = [];
    for (const suggestion of suggestions) {
      const isAvailable = await checkUsernameAvailability(suggestion, false);
      if (isAvailable) {
        availableSuggestions.push(suggestion);
      }
      if (availableSuggestions.length === 3) break; // Limit to 3 suggestions
    }

    setUsernameSuggestions(availableSuggestions);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold">User Profile</h2>
        <div className="flex items-center">
          <div className="w-32 bg-gray-200 rounded-full h-2 mr-2">
            <div 
              className="bg-blue-600 h-2 rounded-full" 
              style={{width: `${completeness}%`}}
            ></div>
          </div>
          <span className="text-sm font-medium">{completeness}%</span>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {step === 1 && (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="w-24 h-24 rounded-full overflow-hidden">
                {formData.profileImage ? (
                  <img src={formData.profileImage} alt="Profile" className="w-full h-full object-cover" />
                ) : (
                  user?.id ? (
                    <FlamingoIdenticon userId={user.id} size={96} />
                  ) : null
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <ProfileImageUpload 
                  onImageUpload={handleProfileImageUpdate}
                  hasExistingImage={!!formData.profileImage}
                />
                {formData.profileImage && (
                  <button
                    type="button"
                    onClick={handleProfileImageDelete}
                    className="text-red-500 text-sm hover:text-red-600 transition-colors"
                  >
                    Reset to Flamingo
                  </button>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="firstName" className="block mb-1">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block mb-1">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="username" className="block mb-1">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                className={`w-full px-3 py-2 border rounded ${
                  usernameError ? 'border-red-500' : (usernameAvailable && formData.username ? 'border-green-500' : 'border-gray-300')
                }`}
              />
              {usernameError && <p className="text-red-500 text-sm mt-1">{usernameError}</p>}
              {!usernameAvailable && usernameSuggestions.length > 0 && (
                <div className="mt-2">
                  <p className="text-sm font-medium">Suggested usernames:</p>
                  <ul className="list-disc list-inside">
                    {usernameSuggestions.map((suggestion, index) => (
                      <li key={index} className="text-sm">
                        <button
                          type="button"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, username: suggestion }));
                            checkUsernameAvailability(suggestion);
                          }}
                          className="text-blue-500 hover:underline"
                        >
                          {suggestion}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="phone" className="block mb-1">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phoneInputValue}
                onChange={handleChange}
                placeholder="+1 (XXX) XXX-XXXX"
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div>
              <label htmlFor="bio" className="block mb-1">Bio</label>
              <textarea
                id="bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-1">Interests</label>
              {['Networking', 'Job Seeking', 'Mentorship', 'Collaboration'].map((interest) => (
                <div key={interest} className="flex items-center">
                  <input
                    type="checkbox"
                    id={interest}
                    checked={formData.interests.includes(interest)}
                    onChange={() => handleInterestChange(interest)}
                    className="mr-2"
                  />
                  <label htmlFor={interest}>{interest}</label>
                </div>
              ))}
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div>
              <label htmlFor="linkedin" className="block mb-1">LinkedIn</label>
              <input
                type="text"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
                placeholder="username or full profile URL"
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="x" className="block mb-1">X (formerly Twitter)</label>
              <input
                type="text"
                id="x"
                name="x"
                value={formData.x}
                onChange={handleChange}
                placeholder="username or @username"
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="instagram" className="block mb-1">Instagram</label>
              <input
                type="text"
                id="instagram"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                placeholder="username or @username"
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </>
        )}

        <div className="flex justify-between mt-4">
          {step > 1 && (
            <button
              type="button"
              onClick={prevStep}
              className="text-gray-600 py-2 px-4 rounded-full hover:bg-gray-100 transition-colors"
            >
              Previous
            </button>
          )}
          {step < 3 ? (
            <button
              type="button"
              onClick={nextStep}
              className="bg-gradient-to-r from-[#ed1942] to-[#ff6b6b] text-white rounded-full py-2 px-4 hover:opacity-90 transition-opacity duration-300"
              disabled={step === 1 && !usernameAvailable}
            >
              {getNextButtonText()}
            </button>
          ) : (
            <button
              type="submit"
              disabled={loading || !usernameAvailable}
              className="bg-gradient-to-r from-[#ed1942] to-[#ff6b6b] text-white rounded-full py-2 px-4 hover:opacity-90 transition-opacity duration-300 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Profile'}
            </button>
          )}
        </div>
      </form>

      <div className="mt-8 border-t pt-8">
        <h3 className="text-lg font-semibold mb-4">Change Email</h3>
        <form onSubmit={handleEmailChangeRequest} className="space-y-4">
          <div>
            <label htmlFor="currentEmail" className="block mb-1">Current Email</label>
            <input
              type="email"
              id="currentEmail"
              value={email}
              disabled
              className="w-full px-3 py-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label htmlFor="newEmail" className="block mb-1">New Email</label>
            <input
              type="email"
              id="newEmail"
              name="newEmail"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter new email"
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <button
            type="submit"
            disabled={isChangingEmail || !newEmail}
            className="w-full bg-gradient-to-r from-[#ed1942] to-[#ff6b6b] text-white rounded-full py-2 px-4 hover:opacity-90 transition-opacity duration-300 disabled:opacity-50"
          >
            {isChangingEmail ? 'Sending...' : 'Change Email'}
          </button>
          {emailChangeMessage && (
            <p className={`text-sm ${emailChangeMessage.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {emailChangeMessage}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserProfileForm;