import React from 'react';

const RoadmapItem = ({ title, description, status }) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 mb-2">{description}</p>
    <span className={`inline-block px-2 py-1 rounded-full text-xs ${
      status === 'Completed' ? 'bg-green-100 text-green-800' :
      status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' :
      'bg-gray-100 text-gray-800'
    }`}>
      {status}
    </span>
  </div>
);

const Roadmap = () => {
  const roadmapData = {
    currentVersion: "1.0.4",
    upcomingFeatures: [
      {
        title: "AI-Powered Icebreakers",
        description: "Integrate AI to generate personalized icebreakers for networking, based on user profiles and interests.",
        status: "Planned"
      },
      {
        title: "Event Creation and Management",
        description: "Allow users to create and manage events, including event-specific QR codes and attendee lists.",
        status: "Planned"
      },
      {
        title: "Enhanced Privacy Settings",
        description: "Implement granular privacy controls for user profiles and connection information.",
        status: "Planned"
      }
    ],
    recentlyCompleted: [
      {
        title: "Connection Management System",
        description: "Implemented a comprehensive connection management system, allowing users to add connections to their flock and view detailed connection profiles.",
        status: "Completed"
      },
      {
        title: "QR Code Scanning",
        description: "Implemented QR code scanning functionality to quickly connect with other users at events.",
        status: "Completed"
      },
      {
        title: "User Profile Customization",
        description: "Added ability for users to customize their profiles with additional information and profile pictures.",
        status: "Completed"
      }
    ],
    ongoingImprovements: [
      {
        title: "Performance Optimization",
        description: "Continuous efforts to improve app performance and load times.",
        status: "Ongoing"
      },
      {
        title: "User Interface Refinement",
        description: "Ongoing improvements to the user interface based on user feedback and usability testing.",
        status: "Ongoing"
      }
    ]
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Flomingl Roadmap</h1>
      <p className="text-gray-600 mb-8">Current Version: {roadmapData.currentVersion}</p>

      <h2 className="text-2xl font-semibold mb-4">Upcoming Features</h2>
      {roadmapData.upcomingFeatures.map((feature, index) => (
        <RoadmapItem key={index} {...feature} />
      ))}

      <h2 className="text-2xl font-semibold mb-4 mt-8">Recently Completed</h2>
      {roadmapData.recentlyCompleted.map((feature, index) => (
        <RoadmapItem key={index} {...feature} />
      ))}

      <h2 className="text-2xl font-semibold mb-4 mt-8">Ongoing Improvements</h2>
      {roadmapData.ongoingImprovements.map((improvement, index) => (
        <RoadmapItem key={index} {...improvement} />
      ))}
    </div>
  );
};

export default Roadmap;