import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleAuthAction = async () => {
    if (user) {
      try {
        await signOut();
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    } else {
      navigate('/login');
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <header className="bg-[#161247] text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/">
            <img src="/images/flomingl-logo-dark.svg" alt="Flomingl Logo" className="h-8 w-auto" />
          </Link>
          {user ? (
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          ) : (
            <button
              onClick={handleAuthAction}
              className="bg-[#ed1942] hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-full"
            >
              Register / Sign In
            </button>
          )}
        </div>
      </header>

      {user && (
        <div className={`fixed inset-y-0 right-0 w-64 bg-white shadow-lg transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-20`}>
          <nav className="flex flex-col p-4">
            <button onClick={toggleMenu} className="self-end mb-4">
              <X size={24} />
            </button>
            <Link to="/dashboard" className={`py-2 ${isActive('/dashboard') ? 'text-purple-700 font-bold' : 'text-gray-800'} hover:text-purple-700`} onClick={toggleMenu}>QR Dashboard</Link>
            <Link to="/connections" className={`py-2 ${isActive('/connections') ? 'text-purple-700 font-bold' : 'text-gray-800'} hover:text-purple-700`} onClick={toggleMenu}>Connections</Link>
            <Link to="/profile" className={`py-2 ${isActive('/profile') ? 'text-purple-700 font-bold' : 'text-gray-800'} hover:text-purple-700`} onClick={toggleMenu}>Profile</Link>
            <button onClick={() => { handleAuthAction(); toggleMenu(); }} className="py-2 text-left text-gray-800 hover:text-purple-700">
              Logout
            </button>
          </nav>
        </div>
      )}

      <main className="flex-grow container mx-auto px-4 py-8">
        {children}
      </main>

      <footer className="bg-gray-100 text-center p-6 mt-8">
        <div className="flex flex-col items-center">
          <img src="/images/flomingl-logo.svg" alt="Flomingl Logo" className="h-8 w-auto mb-2" />
          <p className="text-gray-600" id="copyright">&copy; {new Date().getFullYear()} Flomingl. All rights reserved.</p>
          <div className="flex space-x-4 mt-2">
            <Link to="/roadmap" className="text-purple-700 hover:text-purple-800">Roadmap</Link>
            <a href="https://purplehorizons.io" target="_blank" rel="noopener noreferrer" className="text-purple-700 hover:text-purple-800">Purple Horizons LLC.</a>
          </div>
          <p className="text-sm text-gray-500 mt-4">Built with ❤️ in South Miami</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;