import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './contexts/AuthContext';
import { getSupabase } from './utils/supabaseClient';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AuthCallback from './AuthCallback';
import UserProfileForm from './components/UserProfileForm';
import Roadmap from './pages/Roadmap';
import Scanner from './pages/Scanner';
import UserProfile from './pages/UserProfile';
import Connections from './pages/Connections';
import ConnectionProfile from './pages/ConnectionProfile';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

function AppContent() {
  const { setUser } = useAuth();

  useEffect(() => {
    const supabase = getSupabase();
    
    // Handle the hash if present (for authentication callback)
    const handleHash = async () => {
      const hash = window.location.hash;
      if (hash && hash.substring(1).split('&').find(param => param.startsWith('access_token'))) {
        const accessToken = hash.substring(1).split('&').find(param => param.startsWith('access_token')).split('=')[1];
        const refreshToken = hash.substring(1).split('&').find(param => param.startsWith('refresh_token')).split('=')[1];
        
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        if (error) {
          console.error('Error setting session:', error);
        } else if (data?.user) {
          setUser(data.user);
        }

        // Clear the hash
        window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
      }
    };

    handleHash();

    // Set up auth state listener
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [setUser]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><UserProfileForm /></PrivateRoute>} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/scanner" element={<PrivateRoute><Scanner /></PrivateRoute>} />
        <Route path="/connections" element={<PrivateRoute><Connections /></PrivateRoute>} />
        <Route path="/connections/:username" element={<PrivateRoute><ConnectionProfile /></PrivateRoute>} />
        <Route path="/:userIdentifier" element={<UserProfile />} />
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;