import React, { useState } from 'react';
import { getSupabase } from '../utils/supabaseClient';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

const ProfileImageUpload = ({ onImageUpload, hasExistingImage }) => {
  const [loading, setLoading] = useState(false);
  const supabase = getSupabase();

  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        alert('Please upload a valid image file (JPEG, JPG, PNG, or GIF)');
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        alert('File size exceeds 5MB limit');
        return;
      }

      setLoading(true);

      // Get the current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error getting user:', userError);
        alert('Error uploading image. Please try again.');
        setLoading(false);
        return;
      }

      // Normalize the file extension
      let fileExt = file.name.split('.').pop().toLowerCase();
      if (fileExt === 'jpeg') fileExt = 'jpg';

      const fileName = `${user.id}/${Date.now()}.${fileExt}`;
      const filePath = `${fileName}`;

      // Log the file information
      console.log('File type:', file.type);
      console.log('File path:', filePath);

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) {
        alert('Error uploading image');
        console.error('Upload error:', uploadError);
        setLoading(false);
        return;
      }

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      if (urlError) {
        alert('Error getting public URL');
        console.error(urlError);
        setLoading(false);
        return;
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ profile_image: publicUrl })
        .eq('id', user.id);

      if (updateError) {
        alert('Error updating profile');
        console.error(updateError);
      } else {
        onImageUpload(publicUrl);
      }

      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleImageUpload}
        accept="image/jpeg,image/png,image/gif"
        disabled={loading}
        className="hidden"
        id="profile-image-upload"
      />
      <label
        htmlFor="profile-image-upload"
        className="cursor-pointer text-blue-500 text-sm hover:text-blue-600 transition-colors"
      >
        {loading ? 'Uploading...' : hasExistingImage ? 'Change photo' : 'Add photo'}
      </label>
    </div>
  );
};

export default ProfileImageUpload;