import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { QRCodeSVG } from 'qrcode.react';
import FlamingoIdenticon from '../components/FlamingoIdenticon';
import { getSupabase } from '../utils/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import { isProfileComplete } from '../utils/profileUtils';

const Dashboard = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const supabase = getSupabase();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileAndCheckCompleteness = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching profile:', error);
        } else {
          setProfile(data);
          
          // Check if profile is complete
          const profileComplete = await isProfileComplete(user.id);
          if (!profileComplete) {
            navigate('/profile');
            return;
          }
        }
      }
      setLoading(false);
    };

    fetchProfileAndCheckCompleteness();
  }, [user, navigate, supabase]);

  const userQRData = profile?.username || user?.id; // Use username if available, fallback to user ID

  if (loading) {
    return <div className="flex items-center justify-center min-h-[calc(100vh-8rem)]">Loading...</div>;
  }

  const handleScanClick = () => {
    navigate('/scanner');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-8rem)]">
      <h1 className="text-2xl font-semibold mb-2">Welcome, {profile?.first_name || 'User'}!</h1>
      <p className="text-sm text-gray-600 mb-4">{user?.email}</p>
      
      <Link to="/profile" className="relative group mb-8">
        <div className="w-24 h-24 rounded-full overflow-hidden shadow-[0_4px_10px_rgba(0,0,0,0.1)] transition-all duration-300 ease-in-out group-hover:shadow-[0_8px_15px_rgba(0,0,0,0.2)] group-hover:-translate-y-1">
          {profile?.profile_image ? (
            <img src={profile.profile_image} alt="Profile" className="w-full h-full object-cover" />
          ) : (
            <FlamingoIdenticon userId={user?.id} size={96} />
          )}
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out group-hover:-translate-y-1">
          <span className="text-white text-sm font-medium">Edit Profile</span>
        </div>
      </Link>

      <div className="bg-white rounded-lg shadow-[0_-4px_10px_rgba(0,0,0,0.1),0_4px_10px_rgba(0,0,0,0.1)] p-6 mb-8">
        <QRCodeSVG value={userQRData} size={256} />
      </div>

      <button 
        onClick={handleScanClick}
        className="bg-gradient-to-r from-[#ed1942] to-[#ff6b6b] text-white rounded-full py-3 px-6 hover:opacity-90 transition-opacity duration-300"
      >
        Scan QR Code
      </button>
    </div>
  );
};

export default Dashboard;